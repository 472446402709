import React, { useState, useCallback } from "react"
import { Collapse } from 'react-collapse';
import { getSlugFromString } from "../components/utils";
import PlusButton from "../images/icon-plus-button.svg";
import styled from "styled-components";
import { notSmall } from "../styles/mediaQueryMixins";

const ToggleButton = styled.button`
  font-family: 'Source Serif Pro';
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: 300;
  color: #3139FB;
  background: none;
  border: none;
  text-indent: 25px;
  height: 40px;
  padding: 0;

  ${notSmall`
    text-indent: 42px;
    height: 60px;
  `};

  &:before {
    content: " ";

    display: block;
    position: absolute;

    width: 20px;
    height: 20px;
    margin-top: 3px;
    background-size: 14px;

    background-image: url(${PlusButton});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform: rotate(${props => props.isOpened ? '45deg' : '0'});
    transition: .1s transform;

    ${notSmall`
      width: 30px;
      height: 30px;
      margin-top: 5px;
      background-size: 24px;
    `}
  };
`

const ToggleBody = styled.div`
  margin-left: 25px;

  ${notSmall`
    margin-left: 42px;
  `};
`

export const ToggleItem = ({ heading, children }) => {
  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false);
  const onClick = useCallback(
    () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
    [isButtonCollapseOpen]
  );
  const accessibilityId = getSlugFromString(`toggle ${heading}`);

  // flatmap children
  children = children.map(child => {
    if (child.props?.children) {
      return child.props.children
    } else {
      return child
    }
  });

  return (
    <>
      <ToggleButton
        aria-controls={accessibilityId}
        aria-expanded={isButtonCollapseOpen}
        onClick={onClick}
        type="button"
        isOpened={isButtonCollapseOpen}
      >
        {heading}
      </ToggleButton>
      <Collapse isOpened={isButtonCollapseOpen}>
        <ToggleBody id={accessibilityId}>
          {children}
        </ToggleBody>
      </Collapse>
    </>
  )
}