
export const HELLO_EMAIL_TEXT_ONLY = 'hello@thebrowser.company';
export const HELLO_EMAIL = 'mailto:' + HELLO_EMAIL_TEXT_ONLY;
export const CAREERS_EMAIL_TEXT_ONLY = 'careers@thebrowser.company';
export const CAREERS_EMAIL = 'mailto:' + CAREERS_EMAIL_TEXT_ONLY;
export const TEAM_URL = 'https://thebrowser.company/#team';
export const JOBS_URL = '/careers';
export const NEWSLETTER_URL = "https://browsercompany.substack.com/";
export const TWITTER_URL = "https://twitter.com/browsercompany"
export const WAITLIST_FORM_URL = "https://browserco.typeform.com/to/l9lYbJtU";
export const VALUES_URL = "https://thebrowser.company/values/";
export const ARC_NET_URL = "https://arc.net";
