import slugify from "slugify";
import { offColor } from 'off-color'
import contrast from 'get-contrast'

export function range(n: number): number[] {
    let r: number[] = [];
    for (let i=0; i<n; i++) {
        r.push(i);
    }
    return r;
}
export function remap(x: number, domain: [number, number], range: [number, number]): number {
    const [d1, d2] = domain;
    const [r1, r2] = range;
    const t = d2 === d1 ? 0 : (x - d1) / (d2 - d1);
    const clampedT = Math.max(0, Math.min(1, t));
    return r1 + clampedT * (r2 - r1);
}

/**
 * Truncate a string to full words, then turn it into a valid slug
 * @param str
 * @returns slug A valid url slug
 */
 export const getSlugFromString = (str, maxLength = 20) => {
    let shortened = str;

    if (shortened.length > maxLength) {
      const breakpoint = shortened.substr(0, maxLength).lastIndexOf(" ");
      shortened = shortened.substr(0, breakpoint)
    }

    return slugify(shortened, { lower: true, strict: true })
}

/**
 * Returns an accessible color
 * @param foreground
 * @param background
 * @param count
 * @returns color A hex color passing a contrast check
 */
export const getAccessibleColor = (foreground = "#000", background = "#FFF", loop = 0) => {
    const maxLoops = 10
    const contrastAdjustmentPerLoop = 0.025

    // Prevent infinite loops, or spending too much
    // time calculating accessible colors
    // (safeguard negative numbers if someone accidentally passes one in)
    if (loop > maxLoops || loop < 0) {
      return foreground
    }

    if (contrast.isAccessible(foreground, background)) {
      return foreground
    } else {
      if (offColor(background).light) {
        const darkerForeground = offColor(foreground).darken(contrastAdjustmentPerLoop).hex()
        return getAccessibleColor(darkerForeground, background, loop + 1)
      } else {
        const lighterForeground = offColor(foreground).lighten(contrastAdjustmentPerLoop).hex()
        return getAccessibleColor(lighterForeground, background, loop + 1)
      }
    }
  }
