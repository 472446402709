/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { JOBS_URL } from './src/components/Constants';

// Fonts
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/space-mono/400.css";
import "@fontsource/source-serif-pro/300.css";
import "@fontsource/source-serif-pro/600.css";

export { wrapRootElement } from './src/wrap-root-element'

const removeTrailingSlash = (str) => {
    const needle = str.length - 1
    return str.charAt(needle) === "/" ? str.substring(0, needle) : str;
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    prevRouterProps
  }) => {
    const newPathName = location?.pathname ?? ""
    const prevPathName = prevRouterProps?.location?.pathname ?? ""

    const newPathNameNormalized = removeTrailingSlash(newPathName)
    const prevPathNameNormalized = removeTrailingSlash(prevPathName)

    // If we're navigating from the jobs page, to a new location on the jobs page
    if (newPathNameNormalized === JOBS_URL && prevPathNameNormalized === JOBS_URL) {
        // Gastby really likes to scroll to the top
        // of the page when removing a hash from the url.
        // this way, if the hash is being set to empty,
        // we tell gastby to _not_ scroll.
        if (location.hash === "") {
            return false;
        } else {
            return true;
        }
    } else {
        // If we're not on the jobs page, do
        // the default behavior as stated here
        // https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
        return true;
    }
  };