import React from "react";
import { css } from "styled-components";

/**
 * Media Query Mixins
 * These are based on tachyons, where you build a page
 * mobile-first, and then add styles to render correctly on
 * larger devices.
 */
 export const notSmall = (...args) => css`
 @media screen and (min-width: 30em) {
   ${css(...args)}
 }
`;

export const medium = (...args) => css`
 @media screen and (min-width: 30em) and (max-width: 60em) {
   ${css(...args)}
 }
`;

export const large = (...args) => css`
 @media screen and (min-width: 60em) {
   ${css(...args)}
 }
`;